<template>
    <v-container>
        <label :for="'fileInput' + index" class="dropzone pa-4" :class="{ 'dropzone-active': isDragActive }"
            @dragover.prevent="handleDragOver" @dragleave.prevent="handleDragLeave" @drop.prevent="handleFileUpload">
            <span v-if="!fileUploaded && index === 0">データセットの追加（CSVファイルをここにドロップまたはクリック、ファイルサイズは{{ upperFileSize }}MBまで）</span>
            <span v-else-if="!fileUploaded && index === 1">テストデータの追加（CSVファイルをここにドロップまたはクリック）</span>
            <input type="file" :id="'fileInput' + index" ref="fileInput" style="display: none;" @change="handleFileUpload"
                accept=".csv" />
            <v-alert v-if="unsupportedFormat" class="mt-3" type="error">
                CSVファイルではありません。CSVファイルを選択してください。
            </v-alert>
            <v-alert v-if="uploadFailed" class="mt-3" type="error">
                アップロードに失敗しました。再度お試しください。
            </v-alert>
            <v-alert v-if="fileSizeExceeded" class="mt-3" type="error">
                ファイルサイズが{{ upperFileSize }}MBを超えています。{{ upperFileSize }}MB以下のファイルを選択してください。
            </v-alert>
            <v-alert v-if="fileContentsIncorrected" class="mt-3" type="error">
                {{ fileContentsIncorrectedMessage }}
            </v-alert>
            <div v-if="fileUploaded" class="mt-3">
                <v-chip color="primary" dark label small>
                    <v-icon small dense class="mr-1">mdi-file</v-icon>
                    {{ uploadedFileName + ` (${fileSize} B) ` }}
                    <v-icon @click="deleteFile" class="ml-2" small>mdi-close-circle</v-icon>
                </v-chip>
            </div>
        </label>
    </v-container>
</template>

<script>
import commonMixin from '@/mixins/commonMixin.js';
export default {
    mixins: [commonMixin],
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isDragActive: false,
            unsupportedFormat: false,
            uploadFailed: false,
            fileSizeExceeded: false,
            fileContentsIncorrected: false,
            fileContentsIncorrectedMessage: null,
            fileUploaded: false,
            uploadedFileName: null,
            fileSize: null,
        };
    },
    computed: {
        upperFileSize() {
            if (this.projectClassType === 'Regression' || this.projectClassType === 'Classification') {
                return 100;
            }else {
                return 9;
            }
        },
    },
    methods: {
        handleDragOver(event) {
            event.preventDefault();
            this.isDragActive = true;
        },
        handleDragLeave(event) {
            event.preventDefault();
            this.isDragActive = false;
        },
        handleFileUpload(event) {
            this.isDragActive = false;
            const files = event.target.files || event.dataTransfer.files;
            if (this.upperFileSize === 100 && files[0].size > 104857600) {
                this.fileSizeExceeded = true;
            }
            else if (this.upperFileSize === 9 && files[0].size > 9437184) {
                this.fileSizeExceeded = true;
            }
            else if (files.length > 0) {
                const file = files[0];
                if (file.type === "text/csv") {
                    this.resetFlgs();
                    this.fileUploaded = true;
                    this.uploadedFileName = file.name;
                    this.fileSize = file.size;

                    this.$emit("fileUploaded", { index: this.index, file });
                } else {
                    this.unsupportedFormat = true;
                }
            }else {
                this.uploadFailed = true;
            }
        },
        deleteFile() {
            setTimeout(() => {
                this.uploadedFileName = null;
                this.fileSize = null;
                this.$refs.fileInput.value = "";
                this.$emit("fileDeleted", this.index);
                this.fileUploaded = false; // Reset the uploaded file status
            }, 10);
        },
        fileFormatIncorrectedAlert(message) {
            this.fileContentsIncorrectedMessage = message;
            this.fileContentsIncorrected = true;
        },
        resetFlgs(){
            this.unsupportedFormat = false;
            this.uploadFailed = false;
            this.fileSizeExceeded = false;
            this.fileContentsIncorrected = false;
        },
    },
};
</script>